<template>
  <div :class="'comment-card'">
    <div class="title">
      <div class="title__header">
        <img
            :src="videoDetail[`interaction_btn_${type}`].btn_icon_url"
            class="icon icon-reaction mr-2"
            alt=""
        >
        <div
            class="text"
            :style="{'color' : videoDetail[`interaction_btn_${type}`].color}"
        >{{ videoDetail[`interaction_btn_${type}`].text }}
        </div>
      </div>
      <div class="list-comment-type">
        <span class="comment-type" :class="{'active' : item.value == currentCommentType}"
              v-for="(item, key) in Object.values(COMMENT_TYPE)" :key="key" @click="changeCommentType(item.value)">
          <span class="comment-type-space" v-if="key !== 0">|</span>
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="card-comment-ai w-100"
         :class="{'order-by-video': order === ORDER_COMMENT.VIDEO.value && commentsCard.length/pageSize > pageNumber}">
      <div
          class="comment__item"
          v-for="(item, index) in getContents"
          :key="index"
      >
        <div class="comment__item--header" :style="{'backgroundColor' : videoDetail[`interaction_btn_${type}`].backgroundColor}">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="number">
            {{ item.total_interaction | decimalOnePoint }}%
          </div>
          <div class="index" :style="{'color' : videoDetail[`interaction_btn_${type}`].color}">{{ (pageNumber - 1) * pageSize + index + 1 }}</div>
        </div>
        <div class="comment__item--body">
          <template v-if="item.comments[currentCommentType].length">
            <div
                v-for="(content, i) in item.comments[currentCommentType]"
                class="comment__item--content"
                :key="i"
                :style="{'color' : videoDetail[`interaction_btn_${type}`].color}"
            >
              {{ content }}
            </div>
          </template>
          <div v-else class="comment__item--content-empty">
            {{ COMMENT_TYPE[currentCommentType].name }}のコメントデータは現在表示できません。
          </div>
        </div>
      </div>
      <div v-if="order === ORDER_COMMENT.VIDEO.value && commentsCard.length/pageSize > pageNumber"
           class="comment__item bonus"
      >
        <div class="comment__item--header" :style="{'backgroundColor' : videoDetail[`interaction_btn_${type}`].backgroundColor}">
        </div>
      </div>
    </div>
    <div class="btn-next-prev" v-if="commentsCard && commentsCard.length > 3 && order == ORDER_COMMENT.VIDEO.value">
      <i v-show="pageNumber >  1" @click="prePage" class="icon icon-prev" ref="prevButton"></i>
      <i v-show="commentsCard.length/pageSize > pageNumber" @click="nextPage" class="icon icon-next"
         ref="nextButton"></i>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from "@/commons/helpers"
import {
  ORDER_COMMENT,
  COMMENT_TYPE,
} from "@/commons/constants";

export default {
  props: {
    type: Number,
    commentsCard: Array,
    order: { type: Number, default: ORDER_COMMENT.INTERACTION.value },
    videoDetail: Object,
  },
  filters: {
    decimalOnePoint: function (numberString) {
      return Number(numberString).toFixed(1)
    }
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 3,
      numberWithCommas,
      ORDER_COMMENT,
      COMMENT_TYPE,
      currentCommentType: COMMENT_TYPE.verb.value,
    };
  },
  computed: {
    getContents() {
      return this.commentsCard.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
  },
  methods: {
    nextPage() {
      this.pageNumber++
    },
    prePage() {
      this.pageNumber--
    },
    changeOrder() {
      this.$emit("changeOrder")
    },
    changeCommentType(type) {
      this.currentCommentType = type
    }
  },
  watch: {
    order() {
      this.pageNumber = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_comment_card.scss";
</style>