<template>
  <div class="modal setting-average">
    <div class="modal-content" style="width : 594px">
      <div class="header">
        <div class="btn-close" @click="closeModal">
          <i class="icon icon-close"></i>
        </div>
        <div class="modal-title">平均値設定</div>

      </div>
      <div class="body">
        <div class="description">平均値に含む動画を選択します。選択した動画は全て平均値に含まれます。</div>
        <div class="list-project mt-8">
          <div class="title">平均値に含む動画</div>
          <div class="list-item mt-2">
            <div class="mr-5 mb-2" v-for="video in videosToSelect" :key="'video-' + video.id" :id="'video-' + video.id">
              <div class="item" @click="checkedVideo(video)">
                <i class="icon icon-checkbox" :class="{ checked: video.checked }"></i>
                <div class="item-name pl-2">
                  {{ video.name }}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="list-video-length mt-8">
          <div class="title">動画の長さ別表示</div>
          <div class="list-item mt-2">
            <div class="mr-5 mb-2" v-for="time in videoTimeRanges" :key="'video-time-range-' + time.value">
              <div class="item" @click="checkedTimeRange(time)">
                <i class="icon icon-checkbox" :class="{ checked: time.checked }"></i>
                <div class="item-name pl-2">
                  {{ time.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-submit mt-5">
          <button :disabled="!videoTimeRangesSelected.length" class="btn btn-primary button-download-pdf"
                  @click="settingAverage"> 設定する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/routes';
import {videoTimeRange} from "@/commons/constants"
import EventBus from '@/commons/event_bus';
import {listVideoPromise} from "@/commons/api_service";

export default {
  props: {
    buyerId: Number,
    videos: Array,
  },
  watch: {},
  data() {
    return {
      videosToSelect: [],
      videoTimeRanges: videoTimeRange,
      videoIdsSelected: [],
      videoTimeRangesSelected: [],
      isSetting: false,
      videoIds: [],
      videoTimeRangeValues: [],
      key: 1,
      commonArr: [],
      temporaryArr: [],
      clickDefault: false,
    }
  },
  methods: {
    getVideos() {
      let query_videos = this.$route.query
      query_videos.deliver_period_type = '1';
      const listVideosPromise = listVideoPromise(query_videos)
      listVideosPromise.then((data) => {
        this.videosToSelect = data
        this.videosToSelect.forEach(item => {
          this.$set(item, "checked", true)
        })
        this.videoIdsSelected = this.videosToSelect.map(item => item.id);
        this.videoIds = [...this.videoIdsSelected]
      })
    },

    closeModal() {
      let checkingVideo, checkingTimeRange;
      this.videoIdsSelected = JSON.parse(JSON.stringify(this.videoIds));
      this.videoTimeRangesSelected = JSON.parse(JSON.stringify(this.videoTimeRangeValues));
      this.videosToSelect.forEach(item => {
        checkingVideo = this.checkingVideo(item.id)
        if (checkingVideo) {
          this.$set(item, "checked", true)
        } else {
          this.$set(item, "checked", false)
        }
      })
      this.videoTimeRanges.forEach(item => {
        checkingTimeRange = this.checkingTimeRange(item.value);
        if (checkingTimeRange) {
          this.$set(item, "checked", true)
        } else {
          this.$set(item, "checked", false)
        }
      })
      this.$emit('closeModal')
    },
    checkingVideo(id) {
      let result;
      result = this.videoIds.find(item => item == id);
      return !!result;
    },
    checkingTimeRange(value) {
      let result;
      result = this.videoTimeRangeValues.find(item => item.value == value);
      return !!result;
    },
    checkedVideo(video) {
      this.$set(video, "checked", !video.checked);
      let index = this.videoIdsSelected.findIndex((e) => e == video.id);
      if (video.checked) {
        this.videoIdsSelected.push(video.id)
      } else {
        this.videoIdsSelected.splice(index, 1)
      }
    },
    checkedTimeRange(time) {
      this.$set(time, "checked", !time.checked);
      let index = this.videoTimeRangesSelected.findIndex((e) => e.value == time.value);
      if (time.checked) {
        this.videoTimeRangesSelected.push(time)
      } else {
        this.videoTimeRangesSelected.splice(index, 1);
      }
    },
    reloadSettingAverage() {
      this.videoTimeRanges.forEach(item => {
        this.$set(item, "checked", false)
      })
      this.videosToSelect.forEach(item => {
        this.$set(item, "checked", true)
      })
      this.videoIdsSelected = this.videosToSelect.map(item => item.id);
      this.videoIds = [...this.videoIdsSelected];
      let defaultTimeRange = this.videoTimeRanges[0];
      this.$set(defaultTimeRange, "checked", true);
      this.videoTimeRangesSelected = this.videoTimeRanges.filter(item => item.checked);
      this.videoTimeRangeValues = [...this.videoTimeRangesSelected]
    },
    // api
    settingAverage() {
      let videoTimeRangeSelected = this.videoTimeRangesSelected.sort(function (a, b) {
        return a.value - b.value
      })

      let list_time_range = this.videoTimeRangesSelected.map(item => item.value).join(',')
      this.getSummary(list_time_range).then((data) => {
        let result = this.convertDataNorm(videoTimeRangeSelected, data)
        let dataSetting = {
          videoTimeRange: videoTimeRangeSelected,
          videoIds: this.videoIdsSelected,
          summaryAvg: result
        }
        this.$emit('setSeriesData', dataSetting);
        this.$emit('closeModal');
        this.isSetting = true;
        this.videoIds = [...this.videoIdsSelected];
        this.videoTimeRangeValues = [...this.videoTimeRangesSelected];
      })
    },
    convertDataNorm(videoTimeRangeSelected, data) {
      let result = JSON.parse(JSON.stringify(data))
      let defineId = {
        'avg': "total_avg",
        'avg-0-15': '0_15_avg_norm',
        'avg-16-30': '16_30_avg_norm',
        'avg-31-60': '31_60_avg_norm',
        'avg-61': '61_avg_norm',
      }
      if (!Object.keys(result).length) {
        videoTimeRangeSelected.map(function (item) {
          result[defineId[item.id]] = {
            '1_avg_norm': 0,
            '2_avg_norm': 0,
            '3_avg_norm': 0,
          }
        })
      }
      return result
    },
    getSummary(time) {
      let query = {
        buyer_id: this.$props.buyerId,
      }
      let params = {
        video_ids: this.videoIdsSelected + '',
        video_time_range: time,
        interaction_tally: 1
      }
      return new Promise((resolve, reject) => {
        let url = router.resolve({name: 'api.buyer.videos_norm_promotion', params: query}).href;
        this.$http2.get(url, params, data => {
          resolve(data);
        }, error => {
          this.$http2.showToastError(error.body.Message)
          reject(error);
        });
      })
    }
  },
  mounted() {
    this.getVideos()
    this.videoTimeRanges.forEach(item => {
      this.$set(item, "checked", false)
    })
    if (!this.isSetting) {
      let defaultTimeRange = this.videoTimeRanges[0];
      this.$set(defaultTimeRange, "checked", true);
    }
    this.videoTimeRangesSelected = this.videoTimeRanges.filter(item => item.checked);
    this.videoTimeRangeValues = [...this.videoTimeRangesSelected]
  },
  created() {
    EventBus.$on('reloadSettingAverage', this.reloadSettingAverage)
  },
  destroyed() {
    EventBus.$off('reloadSettingAverage', this.reloadSettingAverage)

  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/_setting_average_modal.scss';
</style>