<template>
  <div class="row top" v-if="!showTopBefore">
    <div class="col-9 mr-0 outline-area">
      <div class="flex items-center">
        <div class="font-bold title-page">比較レポート</div>
      </div>
      <div class="video-report-area mt-4" v-show="isLoaded">
        <summary-chart
          :isGetVideosSuccess="isGetVideosSuccess"
          :videos="videos"
          :videoDetail="videoDetail"
          :summaryAvg="summaryAvg"
          @selectVideo="onSelectVideo"
          :dataReport="dataReport"
          :adId="projectIdSelected"
          :buyerId="clientIdSelected"
          @loadVideosImages="loadedData"
          :checkedPrj="checkedPrj"
          :project="firstVideo"
        ></summary-chart>
      </div>
      <Comment
        v-if="isLoaded"
        :comments="comments"
        :order="order"
        :videoDetail="videoDetail"
        @changeOrder="changeOrder"
        :isLoadingComment="isLoadingComment"
      >
      </Comment>
    </div>
    <div class="col-4 detail-area" v-show="isLoaded">
      <right-side
        :videos="checkedVideos"
        :fromRouter="'top'"
        :isProject="isProject"
        :dataReport="dataReport ? dataReport.report_data : []"
      ></right-side>
    </div>
    <div class="background" :style="styleVideoBackground" v-if="!isLoaded">
      <div class="loader"></div>
    </div>
  </div>
  <div v-else class="top top-before">
    <div class="side-left">
      <div class="title-side-left">
        <div class="title-large">Welcome to PlayAds</div>
        <div class="title-small mt-6">
          動画コンテンツの反応値を測定して、
          <br/>
          安全かつ効果的に配信しよう！
        </div>
        <div
          class="btn btn-register btn-primary-outline"
          @click="gotoRegisterProject(clientIdSelected)"
        >
          案件を登録する
        </div>
      </div>
    </div>
    <div class="side-right bg-primary">
      <img class="img-logo" src="images/svg/logo_before_post.svg"/>
      <img src="images/svg/bg_login_logo.svg"/>
    </div>
  </div>
</template>

<script>
import SummaryChart from "@/components/Top/Step-1/SummaryChart.vue";
import RightSide from "@/components/Top/Step-1/RightSide.vue";
import {
  listInteractionComments,
  listVideoPromise,
  reportTopStep1,
  runBatch,
  videosNormReportStep1,
} from "@/commons/api_service";
import EventBus from "@/commons/event_bus";
import {getUrlQuery} from "@/commons/helpers";
import Comment from "@/components/Top/Step-1/Comment.vue";
import { backgroundColorInteraction, colorInteraction, ORDER_COMMENT } from "@/commons/constants";

export default {
  components: {
    SummaryChart,
    RightSide,
    Comment,
  },
  name: "TopCompare",
  props: {},
  data() {
    return {
      clients: [],
      currentUser: {},
      clientIdSelected: null,
      projectIdSelected: null,
      projects: [],
      videos: [],
      checkedVideos: [],
      reportData: [],
      reports: [],
      dataReport: {
        report_data: [],
      },
      summaryAvg: {},
      showTopBefore: false,
      styleVideoBackground: {
        width: "calc(100% - 66px)",
        height: "100%",
        top: "0px",
        position: "fixed",
        display: "flex",
        right: 0,
      },
      isLoaded: false,
      isProject: true,
      checkedPrj: true,
      firstVideo: {},
      is_stack_bar: 0,
      isLoading: false,
      isGetVideosSuccess: false,
      selectedVideoIdsFomHome: "",
      comments: {},
      order: ORDER_COMMENT.INTERACTION.value,
      isLoadingComment: true,
    };
  },
  computed : {
    videoDetail: function () {
      if(this.videos.length) {
        let video = JSON.parse(JSON.stringify(this.videos[0]))
        if(video) {
          for(let i = 1; i <= 3; i ++) {
            if(video[`interaction_btn_${i}`]?.color) {
              video[`interaction_btn_${i}`].backgroundColor = backgroundColorInteraction[video[`interaction_btn_${i}`].color]?.color
              video[`interaction_btn_${i}`].color = colorInteraction[video[`interaction_btn_${i}`].color]?.color
            }
          }
        }
        return video
      } else return {}
    },
  },
  methods: {
    changeOrder() {
      this.order = this.order === ORDER_COMMENT.INTERACTION.value ? ORDER_COMMENT.VIDEO.value : ORDER_COMMENT.INTERACTION.value
      this.initInteractionComment()
    },
    loadedData() {
      this.isLoaded = true;
      this.checkedPrj = false;
      this.checkedVideos = [...this.videos];
    },

    onSelectVideo(video) {
      if (video.checked) {
        this.checkedVideos.push(video);
      } else {
        this.checkedVideos = this.checkedVideos.filter(
          (item) => item.id !== video.id
        );
      }
    },
    resetCheckedVideo() {
      this.checkedVideos = [];
    },
    clearDataRankingProject() {
      this.listRank2 = [];
      this.listRank3 = [];
      this.maxRank2 = {};
      this.maxRank3 = {};
    },
    prevImages() {
      this.currentImageStep -= 1;
    },
    nextImages() {
      this.currentImageStep += 1;
    },
    gotoVideoDetail(video_id) {
      this.$router.push({
        name: "top-detail",
        params: {
          video_id,
        },
      });
    },
    initData() {
      let query_videos = {
        buyer_id: this.clientIdSelected,
        video_ids: this.selectedVideoIdsFomHome,
        deliver_period_type: 1
      };

      let query_report = {
        buyer_id: this.clientIdSelected,
        video_ids: this.selectedVideoIdsFomHome,
        interaction_tally: 1,
        is_stack_bar: this.is_stack_bar,
      };

      const reportTopPromise = reportTopStep1(query_report);
      reportTopPromise.then((data) => {
        this.dataReport = data;
        this.dataReport.report_data = data.report_data ? data.report_data : [];

        const listVideosPromise = listVideoPromise(query_videos);
        listVideosPromise.then((data) => {
          this.videos = data;
          this.isGetVideosSuccess = true;
          this.firstVideo = data[0];
          this.initInteractionComment();
        });
      });

      videosNormReportStep1(
        (reportAvg) => {
          if(!Object.keys(reportAvg).length) {
            reportAvg = {
              total_avg: {
                "1_avg_norm": 0,
                "2_avg_norm": 0,
                "3_avg_norm": 0,
              }
            }
          }
          this.summaryAvg = reportAvg;
        },
        this.clientIdSelected,
        {video_ids: this.selectedVideoIdsFomHome}
      );
    },
    initInteractionComment() {
      this.isLoadingComment = true;
      let query_interaction_comments = {
        video_ids: this.videos.map(item => item.id).join(","),
        type: this.order,
      };

      let vm = this;
      listInteractionComments(query_interaction_comments, function (data) {
        vm.comments = JSON.parse(JSON.stringify(vm.formatCommentByType(data)))
        vm.isLoadingComment = false;
      })
    },
    formatCommentByType(data) {
      let vm = this;
      let result = {};
      let new_data = JSON.parse(JSON.stringify(data))
      for (let [key, videos] of Object.entries(new_data)) {
        videos = videos.map(function (video) {
          video.name = vm.videos.filter(item => item.id == video.video_id)[0].name ?? ""
          video.total_interaction = vm.dataReport.report_data.filter(item => item.video_id == video.video_id)[0][key + '_interaction_total'] ?? 0
          return video
        })
        result[key] = {}
        result[key].comments = videos
      }
      return result;
    },
    gotoRegisterProject() {
      this.$router.push({name: "project", query: {is_create: 1}});
    },
    handleRunBatch() {
      this.isLoading = true;
      runBatch(() => {
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    let {video_ids, buyer_id} = getUrlQuery();
    this.selectedVideoIdsFomHome = video_ids;
    this.clientIdSelected = Number(buyer_id);
    this.$store.commit("updateSelectedVideoIdsFromHome", video_ids.split(","));
    this.initData();
  },
  created() {
    EventBus.$on("resetVideos", this.resetCheckedVideo);
  },
  beforeDestroy() {
    EventBus.$off("resetVideos");
    this.$store.commit("resetReportSummaryAd");
    this.$store.commit("resetVideosImages");
    this.$store.commit("resetReportSummaryAvg");
    this.$store.commit("resetVideoImages");
    this.$store.commit("resetInteractionRank");
    this.$store.commit("resetInteractionTop");
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variable.scss";

.top::v-deep {
  @import "@/styles/_top.scss";

  &.top-before {
    margin: -20px;
    display: block;
    height: 100vh;
    @media screen and (min-width: 992px) {
      display: flex;
    }

    .side-left {
      background-color: $green;
      color: $white;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 41.32vw;

      .title-side-left {
        .title-large {
          font-weight: bold;
          font-size: 36px;
          line-height: 38px;
          text-align: center;
        }

        .title-small {
          font-style: normal;
          font-weight: bold;
          @include font(xl);
        }

        .btn-register {
          padding: 10px 0px;
          margin-top: 32px;
          text-align: center;
        }
      }
    }

    .side-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      background-color: $green;

      .img-logo {
        position: absolute;
      }
    }
  }
}

.outline-area {
  min-width: unset !important;
}

.title-page {
  @include font(xxl);
}

.compare-data,
.btn-share {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  max-height: 28px;
  background-color: #ffffff;
  border: 1px solid #49ab94;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  span {
    color: #49ab94;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
  }

  .icon-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #edf7f4;
    border-radius: 50%;

    div:first-child {
      width: 4px;
      height: 8px;
      background-color: #49ab94;
      border-radius: 1px;
    }

    div:last-child {
      width: 1px;
      height: 6px;
      margin-left: 1px;
      border: 1px solid #49ab94;
      border-radius: 1px;
    }
  }
}
</style>
<style type='text/css'>
@-moz-document url-prefix() {
  .video-list .body {
    direction: ltr !important;
    scrollbar-width: thin;
  }
}

.background .loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
